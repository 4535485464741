 <div class="columns is-vcentered">
  <div class="column">
    <div class="columns is-mobile" style="margin: 0">
      <div class="column is-narrow">
        <i class="icon icon-invoice" style="margin: 0.5rem"></i>
      </div>
      <div class="column" style="padding-right: 0">
        <div class="attachment-title">
          <h3>{{ "common.invoice" | translate }}</h3>
          {{ invoice.created_at | date : "dd.MM.yyyy" }} |
          {{ invoice.total | currency : currency }}
        </div>
        <div class="attachment-status" [ngSwitch]="invoice.status">
          <span *ngSwitchCase="'open'">{{
            "messaging.invoice-needs-payment" | translate
          }}</span>
          <span *ngSwitchCase="'payment_processing'">{{
            "messaging.invoice-payment-processing" | translate
          }}</span>
          <span *ngSwitchCase="'paid'">{{
            "messaging.invoice-paid" | translate
          }}</span>
          <span *ngSwitchCase="'canceled'">{{
            "messaging.invoice-canceled" | translate
          }}</span>
        </div>
        <div class="actions">
          <button
            *ngIf="invoice.status == 'paid'"
            class="button is-rounded is-small"
            (click)="viewInvoice()"
          >
            {{ "messaging.view-invoice" | translate }}
          </button>
          <ng-container *ngIf="invoice && clientId && currentUser && clientId.toString() == currentUser.id.toString()">
            <button
              *ngIf="invoice.status == 'open'"
              class="button is-primary is-rounded is-small"
              (click)="payAndConfirm()"
            >
              {{ "messaging.pay-invoice" | translate }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
