import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Channel } from 'angular2-actioncable';
import { WebsocketService } from 'src/app/components/messaging/service/websocket.service';
import { User } from 'src/app/entities/user.model';
import { GenericHttpService } from '../../services/Generic HTTP/generic-http.service';
import { FeaturesService } from '../../services/features/features.service';
import { VideoService } from '../../video/video.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'shared-waiting-room-announcement',
  templateUrl: './waiting-room-announcement.component.html',
  styleUrls: ['./waiting-room-announcement.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class WaitingRoomAnnouncementComponent implements OnInit {
  userChannel: Channel;
  hasNotification: boolean = false;

  @Input()
  currentUser: User;

  constructor(
    private webSocketService: WebsocketService,
    private http: GenericHttpService,
    private videoService: VideoService,
    private router: Router,
    private featureService: FeaturesService
  ) {}

  ngOnInit(): void {
    this.userChannel = this.webSocketService.getUserChannel(this.currentUser);
    this.userChannel.received().subscribe((data) => {
      if (
        data.signal === 'new_video_wait' ||
        data.signal === 'remove_video_wait'
      ) {
        this.getClients();
      }
    });
  }

  getClients() {
    this.http.get('/onlinesessions').subscribe((sessions) => {
      this.hasNotification = sessions.length > 0;
    });
  }

  openLobby() {
    this.router.navigate(['/home/video-waiting-room']);
    this.hasNotification = false;
  }
}
