<div class="columns is-vcentered">
  <div class="column">
    <div class="columns is-mobile" style="margin: 0">
      <div class="column is-narrow">
        <i class="icon icon-calendar"></i>
      </div>
      <div class="column" style="padding-right: 0">
        <div class="attachment-title">
          <h3>
            <span *ngIf="appointment.is_online">Online </span>
            <span *ngIf="appointment.status == 'canceled'"
              >{{ "messaging.booking-attachment.title_canceled" | translate }}
            </span>
            <span *ngIf="appointment.status == 'declined'"
              >{{
                "messaging.booking-attachment.title_declined" | translate
              }} </span
            >{{ "messaging.booking-attachment.title_appointment" | translate }}
            <span
              *ngIf="
                appointment.status == 'pending' ||
                appointment.status == 'inquiry' ||
                appointment.status == 'declined'
              "
              >{{
                "messaging.booking-attachment.title_request" | translate
              }}</span
            >
            <span *ngIf="appointment.status == 'completed'">{{
              "messaging.booking-attachment.title_completed" | translate
            }}</span>
          </h3>
          <p>
            {{ appointment.start | momentFormat: "DD.MM.YYYY | HH:mm" }}
            <span *ngIf="appointment.status == 'accepted'"
              >- {{ appointment.end | momentFormat: "HH:mm" }}
            </span>
            <span *ngIf="showTimezone && !!currentUser?.profile?.timezone"
              >({{ currentUser.profile.timezone }})</span
            >
          </p>
          <p *ngIf="ownsAppointment">
            <span *ngIf="appointment.payment_type == 'prepay' && appointment.prepay_price && appointment.prepay_currency">
  {{ "messaging.booking-attachment.payment_type_prepay" | translate : { price: (appointment.prepay_price) | currency : appointment.prepay_currency : 'symbol' : '1.0-0' } }}
</span>
            <span *ngIf="appointment.payment_type == 'invoice_later'"
            > {{ "messaging.booking-attachment.payment_type_invoice_later" | translate  }}</span>
          </p>
        </div>
        <app-appointment-status-message
          [appointment]="appointment"
          [currentUser]="currentUser"
          [profileId]="profileId"
        >
        </app-appointment-status-message>
        <div class="actions has-margin-top-15">
          <app-appointment-actions
            [appointment]="appointment"
            [currentUser]="currentUser"
            [clientId]="clientId"
            [profileId]="profileId"
            [canUseVideo]="canUseVideo"
            [useSmallButtons]="true"
            [editAppointment]="this.editAppointment.bind(this, appointment)"
          ></app-appointment-actions>
        </div>
      </div>
    </div>
  </div>
</div>
