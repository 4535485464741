import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { environment } from './environments/environment';

import { MonitoringService } from './app/shared/services/monitoring/monitoring.service';
import { AppComponent } from './app/app/app.component';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app/app-routing.module';
import { RouterModule } from '@angular/router';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { LegacyAuthModule } from './app/auth/module/auth.module';
import { ICMissingTranslationHandler } from './app/shared/helpers/missing-translations-handler';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import {
  APP_BASE_HREF,
  CommonModule,
  registerLocaleData,
} from '@angular/common';
import { AuthLog } from './app/auth/service/i-c-auth.service';
import { GlobalTokenInterceptor } from './app/shared/global-token.interceptor';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  authGuardFn,
  AuthHttpInterceptor,
  AuthModule,
} from '@auth0/auth0-angular';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter,
  MOMENT,
} from 'angular-calendar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ComponentsRoutingModule } from './app/components/module/components-routing.module';
import { provideNgxStripe } from 'ngx-stripe';
import { OfficeRoutingModule } from './app/office/office-routing.module';
import { notificationGuard } from './app/shared/services/notifications/notifications.guard';
import { preApprovalGuard } from './app/app/pre-approval.guard';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { StreamChatModule, StreamTextareaModule } from 'stream-chat-angular';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import moment from 'moment-timezone';
import { RetryTranslateLoader } from './app/retryTranslateLoader';
import { CatchAllRoutingModule } from './app/components/module/catch-all-routing.module';

MonitoringService.init();

if (environment.production) {
  enableProdMode();
}

if (new URLSearchParams(window.location.search).get('debug') === 'true') {
  MonitoringService.captureMessage('Debug mode test message');
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: ICMissingTranslationHandler,
        },
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => {
            return new RetryTranslateLoader(
              http,
              '/dashboard/assets/i18n/',
              '.json',
              3, // maxRetries
              1000 // initialRetryDelay
            );
          },
          deps: [HttpClient],
        },
      }),
      CalendarModule.forRoot(
        {
          provide: DateAdapter,
          useFactory: momentAdapterFactory,
        },
        {
          dateFormatter: {
            provide: CalendarDateFormatter,
            useClass: CalendarMomentDateFormatter,
          },
        }
      ),
      LegacyAuthModule,
      AuthModule.forRoot({
        ...environment.auth0,
        authorizationParams: {
          redirect_uri: environment.appUrl,
        },
        errorPath: '/',
        httpInterceptor: {
          allowedList: [
            {
              uriMatcher: (uri) => {
                // we want to allow legacy auth to work for our backdoor. Without this, we would try to attach tokens
                // to these legacy auth requests and fail.
                if (uri.includes(environment.apiBase + '/auth')) {
                  AuthLog('Auth0 allowedList: false, URI:', uri);
                  return false;
                } else {
                  const isAuthenticatedEndpoint =
                    !uri.includes(`${environment.apiBase}/public/`) &&
                    !uri.includes(
                      `${environment.apiBase}/onlinesessions/public`
                    ) &&
                    !uri.includes(`${environment.apiBase}/video/credentials`) &&
                    uri.includes(environment.apiBase);
                  AuthLog(
                    'Auth0 allowedList:',
                    isAuthenticatedEndpoint,
                    'URI:',
                    uri
                  );
                  return isAuthenticatedEndpoint;
                }
              },
              tokenOptions: {
                authorizationParams: {
                  audience: environment.auth0.audience,
                },
              },
            },
          ],
        },
      }),
      BrowserModule,
      CommonModule,
      RouterModule,
      AppRoutingModule,
      ComponentsRoutingModule,
      OfficeRoutingModule,
      CatchAllRoutingModule, // This has to go after all other routing modules
      StreamTextareaModule,
      StreamChatModule
    ),
    provideEnvironmentNgxMask(),
    provideAngularSvgIcon(),
    provideNgxStripe(),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', subscriptSizing: 'dynamic' },
    },
    provideCharts(withDefaultRegisterables()),
    { provide: LOCALE_ID, useFactory: determineLocaleId },
    {
      provide: 'AUTH0_HTTP_INTERCEPTOR',
      useClass: AuthHttpInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalTokenInterceptor,
      multi: true,
    },
    {
      provide: MOMENT,
      useValue: moment,
    },
    {
      provide: ErrorHandler,
      useValue: MonitoringService.createGlobalErrorHandler(),
    },
    { provide: 'canActivateRoute', useValue: notificationGuard },
    { provide: 'canActivateRoute', useValue: preApprovalGuard },
    { provide: 'canActivateRoute', useValue: authGuardFn },
    { provide: APP_BASE_HREF, useValue: environment.baseHref },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
  ],
}).catch((err) => console.log(err));

const supportedLocales = ['en', 'de'];
supportedLocales.forEach((locale) => {
  registerLocaleData(localeDe, locale);
  registerLocaleData(localeEn, locale);
});

export function determineLocaleId(): string {
  const browserLocale = navigator.language
    ? navigator.language.split('-')[0]
    : undefined;

  if (browserLocale && supportedLocales.includes(browserLocale)) {
    return browserLocale;
  }

  // if we didn't return yet, the browser's locale isn't supported or is not set
  // so return the default locale
  return 'en';
}

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}
