import { Component, Inject, OnInit, ViewContainerRef } from '@angular/core';
import { Animation } from '../shared/animations/fade-animation';
import { TranslateService } from '@ngx-translate/core';
import { APP_BASE_HREF, NgClass, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { SettingsService } from '../components/settings/service/settings.service';
import { VideoService } from '../shared/video/video.service';
import { ICAuthService } from '../auth/service/i-c-auth.service';
import { SvgIconRegistryService } from 'angular-svg-icon';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { HeaderComponent } from '../frame/header/header.component';
import {
  ChannelService,
  ChatClientService,
  StreamI18nService,
} from 'stream-chat-angular';
import { SharedService } from '../shared/services/shared.service';
import { ChatService } from '../components/messaging/service/chat.service';
import { catchError, firstValueFrom, forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { MonitoringService } from '../shared/services/monitoring/monitoring.service';
import { DebugComponent } from '../debug/debug.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [Animation.fadeAnimation],
  standalone: true,
  imports: [
    NgClass,
    HeaderComponent,
    AlertComponent,
    RouterOutlet,
    DebugComponent,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private auth: ICAuthService,
    private videoService: VideoService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private settings: SettingsService,
    private chatClientService: ChatClientService,
    private channelService: ChannelService,
    private chatService: ChatService,
    private sharedService: SharedService,
    private streamI18nService: StreamI18nService,
    @Inject(ViewContainerRef) private viewContainerRef: ViewContainerRef, // Added private keyword
    private svgIconRegistryService: SvgIconRegistryService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    registerLocaleData(localeDe);

    forkJoin([
      this.translate.getTranslation('en'),
      this.translate.getTranslation('de'),
    ])
      .pipe(
        catchError((error) => {
          MonitoringService.captureMessage('Translation loading failed', {
            extra: error,
          });
          return [[{}, {}]]; // Added error handling
        })
      )
      .subscribe(([_enTranslations, _deTranslations]) => {
        this.streamI18nService.setTranslation('en');
        this.streamI18nService.setTranslation('de', streamTranslationsDE);
        this.settings.setLanguageOnStartup();
      });

    this.route.queryParams.subscribe((params) => {
      if (params.locale) {
        this.settings.useLanguage(params.locale);
      }
    });
    this.videoService.setVideoContainer(viewContainerRef);
    this.addCommonSvgIcons();
  }

  user: boolean;
  isProfile: boolean = false;
  currentComponent: Component;

  ngOnInit() {
    this.auth.initAuth();
    this.auth.isLoggedIn.subscribe(async (userAuthenticated) => {
      if (userAuthenticated) {
        this.sharedService.currentUser.subscribe(async (user) => {
          if (user && environment.getstreamApiKey) {
            try {
              // Added try-catch block
              await this.chatClientService.init(
                environment.getstreamApiKey,
                user.external_id.toString(),
                async () => {
                  const response = await firstValueFrom(
                    this.chatService.getChatToken()
                  );
                  if (!response?.token) {
                    throw new Error('No token received');
                  }
                  return response.token;
                },
                {
                  timeout: 10000,
                  logger: console.log,
                }
              );
              await this.channelService.init(
                {
                  type: 'messaging',
                  members: { $in: [user.external_id.toString()] },
                },
                null,
                null,
                false
              );
            } catch (error) {
              MonitoringService.captureMessage('Chat initialization failed', {
                extra: error,
              });
            }
          }
        });
      } else {
        if (this.chatClientService.chatClient?.user) {
          await this.chatClientService.disconnectUser();
        }
      }
    });
  }

  onActivate(comp: Component) {
    this.currentComponent = comp;
  }

  addCommonSvgIcons() {
    this.svgIconRegistryService.addSvg(
      'badge-w',
      `${this.baseHref}assets/images/svg/badge-w.svg`
    );
    this.svgIconRegistryService.addSvg(
      'badge-g',
      `${this.baseHref}assets/images/svg/badge-g.svg`
    );
    this.svgIconRegistryService.addSvg(
      'plant-with-cup',
      `${this.baseHref}assets/images/svg/plant-with-cup.svg`
    );
    this.svgIconRegistryService.addSvg(
      'profile-picture',
      `${this.baseHref}assets/svg/profile_picture.svg`
    );
    this.svgIconRegistryService.addSvg(
      'green-check',
      `${this.baseHref}assets/images/svg/check-green.svg`
    );
    this.svgIconRegistryService.addSvg(
      'video-person',
      `${this.baseHref}assets/images/svg/video-person.svg`
    );
  }
}

// tslint:disable-next-line:max-line-length
// Translated from https://raw.githubusercontent.com/GetStream/stream-chat-angular/refs/heads/master/projects/stream-chat-angular/src/assets/i18n/en.ts
const streamTranslationsDE = {
  '1 reply': '1 Antwort',
  'Attach files': 'Dateien anhängen',
  Cancel: 'Abbrechen',
  'Channel Missing': 'Kanal fehlt',
  Close: 'Schließen',
  'Close emoji picker': 'Emoji-Auswahl schließen',
  'Commands matching': 'Übereinstimmende Befehle',
  'Connection failure, reconnecting now...':
    'Verbindungsfehler, wird neu verbunden...',
  Delete: 'Löschen',
  Delivered: 'Zugestellt',
  'Edit Message': 'Nachricht bearbeiten',
  'Edit message request failed': 'Bearbeiten der Nachricht fehlgeschlagen',
  'Emoji matching': 'Übereinstimmende Emojis',
  'Empty message...': 'Leere Nachricht...',
  'Error adding flag': 'Fehler beim Hinzufügen der Markierung',
  'Error connecting to chat, refresh the page to try again.':
    'Fehler bei der Verbindung zum Chat, Seite neu laden und erneut versuchen',
  'Error deleting message': 'Fehler beim Löschen der Nachricht',
  'Error loading reactions': 'Fehler beim Laden der Reaktionen',
  'Error muting a user ...': 'Fehler beim Stummschalten eines Benutzers...',
  'Error pinning message': 'Fehler beim Anheften der Nachricht',
  'Error removing message pin': 'Fehler beim Entfernen der Nachricht',
  'Error unmuting a user ...': 'Fehler beim Aufheben der Stummschaltung...',
  'Error uploading file': 'Fehler beim Hochladen der Datei "{{ name }}"',
  'Error uploading file, maximum file size exceeded':
    'Fehler beim Hochladen von "{{ name }}", maximale Dateigröße {{ limit }} überschritten',
  'Error uploading file, extension not supported':
    'Fehler beim Hochladen von "{{ name }}", Dateityp {{ ext }} wird nicht unterstützt',
  'Error deleting attachment': 'Fehler beim Löschen des Anhangs',
  'Error · Unsent': 'Nachricht konnte nicht gesendet werden',
  'Error: {{ errorMessage }}': 'Fehler: {{ errorMessage }}',
  Flag: 'Markieren',
  'Message Failed': 'Nachricht fehlgeschlagen',
  'Message Failed · Unauthorized': 'Nicht berechtigt, Nachricht zu senden',
  'Message Failed · Click to try again':
    'Nachricht konnte nicht gesendet werden, zum erneuten Versuch klicken',
  'Message deleted': 'Nachricht gelöscht',
  'Message has been successfully flagged':
    'Nachricht wurde erfolgreich markiert',
  'Message pinned': 'Nachricht angeheftet',
  'Message unpinned': 'Nachricht nicht mehr angeheftet',
  Mute: 'Stummschalten',
  New: 'Neu',
  'New Messages!': 'Neue Nachrichten!',
  'No results found': 'Keine Ergebnisse gefunden',
  'Nothing yet...': 'Noch nichts...',
  'Only visible to you': 'Nur für dich sichtbar',
  'Open emoji picker': 'Emoji-Auswahl öffnen',
  'People matching': 'Übereinstimmende Personen',
  'Pick your emoji': 'Wähle dein Emoji',
  Pin: 'Anheften',
  'Pinned by': 'Angeheftet von',
  Reply: 'Antwort zitieren',
  'Reply to Message': 'Auf Nachricht antworten',
  Search: 'Suchen',
  'Searching...': 'Suche...',
  Send: 'Senden',
  'Send message request failed': 'Senden der Nachricht fehlgeschlagen',
  'Sending...': 'Senden...',
  'Slow Mode ON': 'Langsamer Modus an',
  'Start of a new thread': 'Beginn eines neuen Threads',
  'This message was deleted...': 'Diese Nachricht wurde gelöscht...',
  Thread: 'Thread-Antwort',
  'Type your message': 'Gib deine Nachricht ein',
  Unmute: 'Stummschaltung aufheben',
  Unpin: 'Lösen',
  'Wait until all attachments have uploaded':
    'Warte, bis alle Anhänge hochgeladen wurden',
  'You have no channels currently': 'Du hast derzeit keine Kanäle',
  "You've reached the maximum number of files":
    'Du hast die maximale Anzahl von Dateien erreicht',
  live: 'live',
  'this content could not be displayed':
    'Dieser Inhalt konnte nicht angezeigt werden',
  '{{ commaSeparatedUsers }} and {{ moreCount }} more':
    '{{ commaSeparatedUsers }} und {{ moreCount }} mehr',
  '{{ commaSeparatedUsers }}, and {{ lastUser }}':
    '{{ commaSeparatedUsers }} und {{ lastUser }}',
  '{{ firstUser }} and {{ secondUser }}':
    '{{ firstUser }} und {{ secondUser }}',
  '{{ imageCount }} more': '{{ imageCount }} mehr',
  '{{ memberCount }} members': '{{ memberCount }} Mitglieder',
  '{{ replyCount }} replies': '{{ replyCount }} Antworten',
  '{{ user }} has been muted': '{{ user }} wurde stummgeschaltet',
  '{{ user }} has been unmuted': '{{ user }} wurde wieder freigeschaltet',
  '{{ watcherCount }} online': '{{ watcherCount }} online',
  '🏙 Attachment...': '🏙 Anhang...',
  'Connection error': 'Verbindungsfehler',
  'Load more': 'Mehr laden',
  failed: 'fehlgeschlagen',
  retry: 'wiederholen',
  test: 'erfolgreich',
  'Sending links is not allowed in this conversation':
    'Das Senden von Links ist in dieser Unterhaltung nicht erlaubt',
  "You can't send messages in this channel":
    'Du kannst in diesem Kanal keine Nachrichten senden',
  "You can't send thread replies in this channel":
    'Du kannst in diesem Kanal keine Antworten senden',
  'Message not found': 'Nachricht nicht gefunden',
  'No chats here yet…': 'Noch keine Chats hier...',
  'user is typing': '{{ user }} tippt',
  'users are typing': '{{ users }} tippen',
  'Error loading channels': 'Fehler beim Laden der Kanäle',
  'See original (automatically translated)':
    'Original anzeigen (automatisch übersetzt)',
  'See translation': 'Übersetzung anzeigen',
  'Mark as unread': 'Als ungelesen markieren',
  'Error marking message as unread': 'Fehler beim Markieren als ungelesen',
  'Error, only the first {{count}} message can be marked as unread':
    'Fehler, nur die ersten {{count}} Nachrichten können als ungelesen markiert werden',
  'Unread messages': 'Ungelesene Nachrichten',
  '{{count}} unread messages': '{{count}} ungelesene Nachrichten',
  '{{count}} unread message': '{{count}} ungelesene Nachricht',
  'This message did not meet our content guidelines':
    'Diese Nachricht entspricht nicht unseren Inhaltsrichtlinien',
  'Send Anyway': 'Trotzdem senden',
  Edited: 'Bearbeitet',
  'Error playing audio': 'Fehler beim Abspielen der Audiodatei',
  'Copy text': 'Text kopieren',
  'Please grant permission to use microhpone':
    'Bitte erteile die Erlaubnis, das Mikrofon zu benutzen',
  'Error starting recording': 'Fehler beim Starten der Aufnahme',
  'An error has occurred during recording':
    'Während der Aufnahme ist ein Fehler aufgetreten',
  'Media recording not supported': 'Medienaufzeichnung wird nicht unterstützt',
};
