import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubmitActionType } from '../submit-action-type';
import { PaymentType } from '../../../entities/PaymentType';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NgxMaskDirective } from 'ngx-mask';
import {
  DatePipe,
  NgClass,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { Appointment } from '../../../entities/appointment.model';
import moment, { Moment } from 'moment-timezone';
import { MomentPipe } from '../booking-form-container/moment.pipe';

@Component({
  selector: 'app-booking-form-details',
  templateUrl: './booking-form-details.component.html',
  styleUrls: ['./booking-form-details.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgxMaskDirective,
    NgClass,
    MatTooltip,
    NgSwitch,
    NgSwitchCase,
    DatePipe,
    TranslateModule,
    MomentPipe,
  ],
})
export class BookingFormDetailsComponent implements OnInit {
  protected readonly SubmitActionType = SubmitActionType;
  protected readonly PaymentType = PaymentType;

  @Input() requestBookingForm: FormGroup;
  @Input() showClient: boolean;
  @Input() showDuration: boolean;
  @Input() timezoneText: string;
  @Input() currency: string;
  @Input() canUseInvoicing: boolean;
  @Input() onChangeClient: () => void;
  @Input() onChangeDate: () => void;
  @Input() onCancel: () => void;
  @Input() onSubmit: () => void;
  @Input() isBusy: boolean;
  @Input() isEAPTherapist: boolean;
  @Input() isEditingEAPSession: boolean;
  @Input() actionType: SubmitActionType;
  @Input() timezone: string;

  selectedClient: any;
  appointmentStart: Moment;
  showEAPBookingOption: boolean;
  cannotBookEAPSessionReason: string;
  canBookCouplesSessions: boolean;

  constructor() {}

  ngOnInit(): void {
    this.selectedClient = this.requestBookingForm.controls.client.value;
    this.appointmentStart =
      this.requestBookingForm.controls.appointmentDate.value;
    // How to show the EAP option. If we are editing an EAP appointment, we should always have a working option
    this.showEAPBookingOption = this.isEAPTherapist || this.isEditingEAPSession;
    this.cannotBookEAPSessionReason = this.isEditingEAPSession
      ? null
      : this.selectedClient.cannot_book_eap_session_reason;
    this.canBookCouplesSessions =
      this.selectedClient.can_book_couples_eap_session;
  }

  setPaymentType(value: PaymentType) {
    this.requestBookingForm.controls.paymentType.setValue(value);
  }

  setIsOnline(value: boolean) {
    this.requestBookingForm.controls.isOnline.setValue(value);
  }

  setIsCouples(value: boolean) {
    this.requestBookingForm.controls.isCouplesSession.setValue(value);
  }

  formattedStartDate(appointmentStart: moment.Moment) {
    return moment(appointmentStart).format('ddd, MMMM D [at] HH:mm');
  }
}
