// share-dialog.component.ts
import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStep, MatStepLabel, MatStepper } from '@angular/material/stepper';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface DialogData {
  profileLink: string;
}

@Component({
  selector: 'app-share-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatStep,
    MatStepLabel,
    MatStepper,
    TranslateModule,
  ],
  templateUrl: 'launcher-share-dialog.component.html',
  styleUrls: ['launcher-share-dialog.component.scss'],
})
export class LauncherShareDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<LauncherShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  copyToClipboard(): void {
    const message = this.getMessage();
    navigator.clipboard.writeText(message);

    this.translate
      .get('launcher.share.copied_notification')
      .subscribe((msg: string) => {
        this.snackBar.open(msg, 'Close', {
          duration: 3000,
        });
      });
  }

  shareOnLinkedIn(): void {
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      this.data.profileLink
    )}`;
    window.open(linkedInUrl, '_blank');
    this.dialogRef.close();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private getMessage(): string {
    return `${this.translate.instant('launcher.share.message')}\n\n${
      this.data.profileLink
    }`;
  }
}
