// debug.component.ts
import { Component, OnInit } from '@angular/core';
import { DebugService } from './debug.service';
import { AsyncPipe, DatePipe, NgForOf, NgIf } from '@angular/common';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-debug',
  template: `
    <div *ngIf="debugService.isDebugMode" class="debug-panel">
      <div class="debug-header">
        <div class="debug-title">
          <h3>Error Monitor</h3>
          <div class="badge error" *ngIf="(errorCount$ | async) > 0">
            {{ errorCount$ | async }} Errors
          </div>
          <div class="badge warning" *ngIf="(warningCount$ | async) > 0">
            {{ warningCount$ | async }} Warnings
          </div>
        </div>
        <div class="debug-controls">
          <div class="filters">
            <label>
              <input
                type="checkbox"
                [checked]="showErrors"
                (change)="showErrors = !showErrors"
              />
              Errors
            </label>
            <label>
              <input
                type="checkbox"
                [checked]="showWarnings"
                (change)="showWarnings = !showWarnings"
              />
              Warnings
            </label>
          </div>
          <button (click)="debugService.clearLogs()" class="clear-btn">
            Clear
          </button>
        </div>
      </div>
      <div class="debug-content">
        <div
          *ngFor="let log of filteredLogs$ | async"
          [class]="'log-entry ' + log.type"
        >
          <div class="log-header">
            <span class="severity">{{ log.type.toUpperCase() }}</span>
            <span class="timestamp">{{
              log.timestamp | date : 'HH:mm:ss.SSS'
            }}</span>
          </div>
          <pre
            class="message"
            [class.empty-error]="log.message === 'Empty error object'"
            >{{ log.message }}</pre
          >
          <div *ngIf="log.message === 'Empty error object'" class="error-tip">
            Tip: Empty error object detected. This might indicate an error being
            thrown without details. Check the code throwing this error to ensure
            error information is being properly included.
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .debug-panel {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        border-top: 2px solid #666;
        max-height: 50vh;
        z-index: 999999;
        font-family: monospace;
        font-size: 12px;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
      }

      .debug-header {
        padding: 8px;
        background: #f0f0f0;
        border-bottom: 1px solid #ccc;
      }

      .debug-title {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 8px;
      }

      .debug-controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .filters {
        display: flex;
        gap: 16px;
      }

      .badge {
        padding: 2px 6px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: bold;
      }

      .badge.error {
        background: #ffebee;
        color: #d32f2f;
      }

      .badge.warning {
        background: #fff3e0;
        color: #ef6c00;
      }

      .clear-btn {
        padding: 4px 8px;
        border-radius: 4px;
        border: 1px solid #ccc;
        background: white;
        cursor: pointer;
      }

      .clear-btn:hover {
        background: #f0f0f0;
      }

      .debug-content {
        overflow-y: auto;
        max-height: calc(50vh - 80px);
        padding: 8px;
      }

      .log-entry {
        margin: 8px 0;
        padding: 8px;
        border-radius: 4px;
      }

      .log-entry.error {
        background: #ffebee;
        border-left: 4px solid #d32f2f;
      }

      .log-entry.warn {
        background: #fff3e0;
        border-left: 4px solid #ef6c00;
      }

      .log-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;
      }

      .severity {
        font-weight: bold;
      }

      .timestamp {
        color: #666;
      }

      .message {
        margin: 4px 0;
        white-space: pre-wrap;
        word-break: break-word;
      }

      .empty-error {
        color: #666;
        font-style: italic;
      }

      .error-tip {
        margin-top: 4px;
        padding: 4px 8px;
        background: #e3f2fd;
        border-radius: 4px;
        font-size: 11px;
        color: #1976d2;
      }
    `,
  ],
  standalone: true,
  imports: [AsyncPipe, NgIf, DatePipe, NgForOf],
})
export class DebugComponent {
  showErrors = true;
  showWarnings = true;

  constructor(public debugService: DebugService) {}

  // Create observables for the counts
  errorCount$ = this.debugService.logs$.pipe(
    map((logs) => logs.filter((log) => log.type === 'error').length)
  );

  warningCount$ = this.debugService.logs$.pipe(
    map((logs) => logs.filter((log) => log.type === 'warn').length)
  );

  filteredLogs$ = this.debugService.logs$.pipe(
    map((logs) =>
      logs.filter(
        (log) =>
          (log.type === 'error' && this.showErrors) ||
          (log.type === 'warn' && this.showWarnings)
      )
    )
  );
}
