import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { User } from '../../../entities/user.model';
import { SharedService } from '../../../shared/services/shared.service';
import { CalendarService } from '../../calendar/service/calendar.service';
import { VideoService } from '../../../shared/video/video.service';
import { AlertService } from '../../../shared/components/alert/service/alert.service';
import { AmplitudeAnalyticsService } from '../../../shared/services/analytics/amplitude-analytics.service';
import { Appointment } from '../../../entities/appointment.model';
import moment from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentCancelConfirmDialog } from '../appointment-cancel-confirm-dialog/appointment-cancel-confirm-dialog';
import { Router } from '@angular/router';
import { PaymentType } from '../../../entities/PaymentType';
import { AppointmentMarkEapCompletedDialog } from '../appointment-mark-eap-completed-dialog/appointment-mark-eap-completed-dialog';
import { FeaturesService } from '../../../shared/services/features/features.service';
import { AppointmentConfirmForClientDialog } from '../appointment-confirm-for-client-dialog/appointment-confirm-for-client-dialog';
import { extractErrorMessage } from '../../../shared/helpers/errors.helper';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgClass } from '@angular/common';
import { MonitoringService } from '../../../shared/services/monitoring/monitoring.service';

enum AppointmentAction {
  SETUP = 'setup',
  CONFIRM = 'confirm',
  CONFIRM_FOR_CLIENT = 'confirm_for_client',
  DECLINE = 'decline',
  CANCEL = 'cancel',
  EDIT = 'edit',
  PAY_AND_CONFIRM = 'pay_and_confirm',
  VIEW_INVOICE = 'view_invoice',
  JOIN_CALL = 'join_call',
  MARK_EAP_SESSION_AS_COMPLETED = 'mark_eap_session_as_completed',
}

@Component({
  selector: 'app-appointment-actions',
  templateUrl: './appointment-actions.component.html',
  styleUrls: ['./appointment-actions.component.scss'],
  standalone: true,
  imports: [NgFor, NgClass, TranslateModule],
})
export class AppointmentActionsComponent implements OnInit {
  @Input() currentUser: User;
  @Input() appointment: Appointment;
  @Input() clientId: number;
  @Input() profileId: number;
  @Input() canUseVideo: boolean;
  @Input() useSmallButtons: boolean = false;
  @Input() sourcePage: 'messages_page' | 'calendar_page';
  @Input() editAppointment: (Appointment) => void;
  @Output() changed = new EventEmitter<number>();

  isAccepting: boolean = false;
  isCanceling: boolean = false;
  isFinalizing: boolean = false;
  isMarkingAsCompleted: boolean = false;
  actions: AppointmentAction[] = [];

  constructor(
    private sharedService: SharedService,
    private calendarService: CalendarService,
    private videoService: VideoService,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef,
    private analytics: AmplitudeAnalyticsService,
    private router: Router,
    public dialog: MatDialog,
    private featureService: FeaturesService
  ) {}

  ngOnInit(): void {
    if (this.canCancel()) {
      this.actions.push(AppointmentAction.CANCEL);
    }
    if (this.canDecline()) {
      this.actions.push(AppointmentAction.DECLINE);
    }
    if (this.canViewInvoice()) {
      this.actions.push(AppointmentAction.VIEW_INVOICE);
    }
    if (this.canEdit()) {
      this.actions.push(AppointmentAction.EDIT);
    }
    if (this.canJoinCall()) {
      this.actions.push(AppointmentAction.JOIN_CALL);
    }
    if (this.canSetup()) {
      this.actions.push(AppointmentAction.SETUP);
    }
    if (this.canConfirmAsClient()) {
      this.actions.push(AppointmentAction.CONFIRM);
    }
    if (this.canConfirmForClient()) {
      this.actions.push(AppointmentAction.CONFIRM_FOR_CLIENT);
    }
    if (this.canPayAndConfirm()) {
      this.actions.push(AppointmentAction.PAY_AND_CONFIRM);
    }
    if (this.canMarkAsEAPSessionCompleted()) {
      this.actions.push(AppointmentAction.MARK_EAP_SESSION_AS_COMPLETED);
    }
  }

  handleAction(action: AppointmentAction) {
    switch (action) {
      case AppointmentAction.SETUP:
        this.setup();
        break;
      case AppointmentAction.CONFIRM:
        this.confirm();
        break;
      case AppointmentAction.CONFIRM_FOR_CLIENT:
        this.confirmForClientConfirm();
        break;
      case AppointmentAction.DECLINE:
        this.decline();
        break;
      case AppointmentAction.CANCEL:
        this.cancelConfirm();
        break;
      case AppointmentAction.PAY_AND_CONFIRM:
        this.payAndConfirm();
        break;
      case AppointmentAction.VIEW_INVOICE:
        this.viewInvoice();
        break;
      case AppointmentAction.JOIN_CALL:
        this.joinCall();
        break;
      case AppointmentAction.EDIT:
        this.analytics.trackAppointmentInteractedWith({
          kind: 'edit',
          source_page: this.sourcePage,
        });
        this.editAppointment(this.appointment);
        break;
      case AppointmentAction.MARK_EAP_SESSION_AS_COMPLETED:
        this.markEapCompletedConfirm();
        break;
    }
  }

  isPerformingAction(action: AppointmentAction): boolean {
    switch (action) {
      case AppointmentAction.CONFIRM:
        return this.isAccepting;
      case AppointmentAction.DECLINE:
      case AppointmentAction.CANCEL:
        return this.isCanceling;
      case AppointmentAction.PAY_AND_CONFIRM:
      case AppointmentAction.VIEW_INVOICE:
        return this.isFinalizing;
      case AppointmentAction.JOIN_CALL:
      case AppointmentAction.EDIT:
        return false;
    }
  }

  isDisabled(action: AppointmentAction): boolean {
    return this.isAccepting || this.isCanceling || this.isFinalizing;
  }

  finalizeInvoice() {
    this.isFinalizing = true;
    this.calendarService
      .createInvoiceForAppointment(this.appointment)
      .subscribe(
        (appt) => {
          this.appointment = appt;
          this.isFinalizing = false;
          this.sharedService.setPayableInvoice(appt.invoice.id);
        },
        (error) => {
          // Handle error response
          this.alertService.error(
            'booking.alerts.appointment_invoice_could_not_be_finalized'
          );
          MonitoringService.captureException('Error creating invoice:', error);
          this.isFinalizing = false;
        }
      );
  }

  setup() {
    this.analytics.trackAppointmentInteractedWith({
      kind: 'inquiry_setup',
      source_page: this.sourcePage,
    });
    // you setup an appointment by editing it. When an appointment inquiry is saved it is "setup" and transitoined to pendin
    this.editAppointment(this.appointment);
  }

  confirm() {
    this.analytics.trackAppointmentInteractedWith({
      kind: 'confirmed',
      source_page: this.sourcePage,
    });
    this.isAccepting = true;
    this.calendarService.acceptAppointment(this.appointment.id).subscribe(
      (response) => {
        // Handle successful response
        this.alertService.success('booking.alerts.appointment_accepted');
        this.isAccepting = false;
        this.changed.emit(this.appointment.id);
      },
      (error) => {
        // Handle error response
        this.alertService.error(
          extractErrorMessage(error, 'booking.alerts.appointment_update_error')
        );
        this.isAccepting = false;
      }
    );
  }

  payAndConfirm() {
    this.analytics.trackAppointmentInteractedWith({
      kind: 'pay_and_confirm',
      source_page: this.sourcePage,
    });
    if (!this.appointment.invoice && !this.appointment.invoice_id) {
      this.finalizeInvoice();
    } else {
      this.sharedService.setPayableInvoice(
        this.appointment.invoice_id || this.appointment.invoice.id
      );
    }
  }

  viewInvoice() {
    this.analytics.trackAppointmentInteractedWith({
      kind: 'view_invoice',
      source_page: this.sourcePage,
    });
    if (this.isTherapist()) {
      this.router.navigate([
        '/office/invoices',
        this.appointment.invoice_id || this.appointment.invoice.id,
      ]);
    } else {
      this.payAndConfirm();
    }
  }

  cancelConfirm() {
    const dialogRef = this.dialog.open(AppointmentCancelConfirmDialog, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cancelAppointment();
      }
    });
  }

  markEapCompletedConfirm() {
    const dialogRef = this.dialog.open(AppointmentMarkEapCompletedDialog, {
      data: {
        sourcePage: this.sourcePage,
        appointmentId: this.appointment.id,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appointment = result;
        this.changed.emit(this.appointment.id);
      }
    });
  }

  confirmForClientConfirm() {
    const dialogRef = this.dialog.open(AppointmentConfirmForClientDialog, {});
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.confirm();
      }
    });
  }

  cancelAppointment() {
    if (this.isCanceling) {
      return;
    }
    this.isCanceling = true;
    this.cdr.detectChanges();

    this.analytics.trackAppointmentInteractedWith({
      kind: 'cancel',
      source_page: this.sourcePage,
    });

    this.calendarService.cancelAppointment(this.appointment.id).subscribe(
      (res) => {
        this.isCanceling = false;
        if (res.status === 'canceled') {
          this.appointment = res;
          this.alertService.success('booking.alerts.appointment_canceled');
          this.changed.emit(this.appointment.id);
        } else {
          this.alertService.error(
            'booking.alerts.appointment_cancellation_error'
          );
        }
        this.cdr.detectChanges();
      },
      (error) => {
        // Handle error response
        this.alertService.error(
          extractErrorMessage(
            error,
            'booking.alerts.appointment_cancellation_error'
          )
        );
        this.isAccepting = false;
      }
    );
  }

  decline() {
    if (this.isCanceling) {
      return;
    }
    this.isCanceling = true;

    this.analytics.trackAppointmentInteractedWith({
      kind: 'decline',
      source_page: this.sourcePage,
    });

    this.calendarService.declineAppointment(this.appointment.id).subscribe(
      (res) => {
        this.isCanceling = false;
        this.alertService.success('booking.alerts.appointment_declined');
        this.changed.emit(this.appointment.id);
      },
      (error) => {
        // Handle error response
        this.alertService.error(
          extractErrorMessage(error, 'booking.alerts.appointment_update_error')
        );
        this.isAccepting = false;
      }
    );
  }

  joinCall() {
    this.analytics.trackVideoCallInitiated({
      source_page: this.sourcePage,
      kind: 'scheduled_private_call',
    });
    this.videoService.navigateToGetstreamVideoCall(
      this.videoService.generateAppointmentCallSlug(
        this.appointment,
        this.profileId
      )
    );
  }

  appointmentActionLabels(action: AppointmentAction) {
    switch (action) {
      case AppointmentAction.SETUP:
        return 'booking.actions.setup';
      case AppointmentAction.CONFIRM:
        return 'booking.actions.confirm';
      case AppointmentAction.CONFIRM_FOR_CLIENT:
        return 'booking.actions.confirm';
      case AppointmentAction.DECLINE:
        return 'booking.actions.decline';
      case AppointmentAction.CANCEL:
        return 'booking.actions.cancel';
      case AppointmentAction.EDIT:
        return 'booking.actions.edit';
      case AppointmentAction.PAY_AND_CONFIRM:
        return 'booking.actions.pay_and_confirm';
      case AppointmentAction.VIEW_INVOICE:
        return 'booking.actions.view_invoice';
      case AppointmentAction.JOIN_CALL:
        return 'booking.actions.join_call';
      case AppointmentAction.MARK_EAP_SESSION_AS_COMPLETED:
        return 'booking.actions.mark_eap_session_as_completed';
    }
  }

  private canConfirmAsClient() {
    if (this.isClient()) {
      // the client can confirm non pre-pay appointments that are pending. Prepay appointments are confirmed when the invoice is paid
      if (
        this.appointment.status === 'pending' &&
        this.appointment.payment_type !== PaymentType.prepay
      ) {
        return true;
      }
    }
    return false;
  }

  private canConfirmForClient() {
    if (this.isTherapist()) {
      if (
        this.appointment.status === 'pending' &&
        this.appointment.payment_type !== PaymentType.prepay
      ) {
        return true;
      }
    }
    return false;
  }

  private canSetup() {
    if (this.isTherapist()) {
      // the therapist can confirm inquiries from clients which should then turn into pending appointments
      if (this.appointment.status === 'inquiry') {
        return true;
      }
    }
    return false;
  }

  private canPayAndConfirm() {
    if (this.isClient()) {
      // the client can pay to confirm pending prepay appointments
      if (
        this.appointment.status === 'pending' &&
        this.appointment.payment_type === 'prepay'
      ) {
        return true;
      }
    }
    return false;
  }

  private canMarkAsEAPSessionCompleted() {
    if (this.isTherapist()) {
      // the therapist can mark as completed when an accepted appointment is in the past
      if (
        this.appointment.status === 'accepted' &&
        this.appointment.payment_type === 'eap'
      ) {
        return moment(this.appointment.start).isBefore(moment());
      }
    }
    return false;
  }

  private canDecline() {
    switch (this.appointment.status) {
      case 'pending':
        return this.isClient();
      case 'inquiry':
        return this.isTherapist();
      default:
        return false;
    }
  }

  private canEdit() {
    return this.appointment.status === 'pending' && this.isTherapist();
  }

  private canCancel() {
    switch (this.appointment.status) {
      case 'pending':
        return this.isTherapist();
      case 'inquiry':
        return this.isClient();
      case 'accepted':
        // appointments can be canceled by either party, but the client only 24+ hours before. pre-pay payments will be
        // refunded as of now, you cannot cancel processing payments (it's pretty complex, you would need to refund, but
        // only if it succeeds, or void if it fails)
        if (this.appointment.invoice?.status === 'payment_processing') {
          return false;
        } else if (this.isClient()) {
          return moment(this.appointment.start)
            .subtract(24, 'hours')
            .isAfter(moment());
        } else {
          return true;
        }
      default:
        return false;
    }
  }

  private canViewInvoice() {
    switch (this.appointment.status) {
      case 'declined':
      case 'cancelled':
        return false;
      default:
        return this.appointment.invoice?.status === 'paid';
    }
  }

  private canJoinCall() {
    return (
      this.canUseVideo &&
      this.appointment.status === 'accepted' &&
      this.appointment.is_online
    );
  }

  private isClient() {
    return this.currentUser.id.toString() === this.clientId.toString();
  }

  private isTherapist() {
    //  GS uses strings, so lets force the comparison to be a string
    return this.currentUser.profile.id.toString() === this.profileId.toString();
  }

  extraClasses(action: AppointmentAction) {
    const extraClasses = [];
    if (this.isPerformingAction(action)) {
      extraClasses.push('is-loading');
    }
    if (this.useSmallButtons) {
      extraClasses.push('is-small');
    }
    switch (action) {
      case AppointmentAction.SETUP:
      case AppointmentAction.CONFIRM:
      case AppointmentAction.PAY_AND_CONFIRM:
      case AppointmentAction.JOIN_CALL:
        extraClasses.push('is-primary');
        break;
      case AppointmentAction.DECLINE:
      case AppointmentAction.CANCEL:
        if (!this.useSmallButtons) {
          // I think danger looks kinda weird on small buttons currently : js
          extraClasses.push('is-warning');
        } else {
          extraClasses.push('is-outlined');
        }
        break;
      default:
        extraClasses.push('is-outlined');
        break;
    }
    return extraClasses;
  }
}
