import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NotificationSettings } from 'src/app/entities/notification-settings.model';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { WeeklyAvailabilitySlot } from '../../../entities/availability-slot.model';
import { User } from '../../../entities/user.model';

import moment from 'moment';
import { CookieService } from 'ngx-cookie';
import { ClientProfile } from 'src/app/entities/client-profile';
import { SubscriptionStatus } from '../../../entities/subscription-status.model';
import { SubscriptionBilling } from '../../../entities/subscription-billing.model';
import { DAYS_OF_WEEK } from 'angular-calendar';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private http: GenericHttpService,
    private translate: TranslateService,
    private cookies: CookieService
  ) {}

  updateCurrentUserV2 = (
    user: User,
    firstname?: string,
    lastname?: string
  ): Observable<{ user: User }> => {
    return this.http.put('/current_user', {
      firstname: firstname,
      lastname: lastname,
      email: user.email,
      locale: user.locale,
      timezone: user.profile.timezone,
      marketing_email_opt_in: user.marketing_email_opt_in,
      newsletter_email_opt_in: user.newsletter_email_opt_in,
      eap_access_code: user.eap_access_code,
    });
  };

  deleteUser = (user: User) => this.http.delete('/users/' + user.id);

  getAvailabilitySlots = (): Observable<any> => this.http.get('/availability');

  deleteAvailabilitySlot = (slotID): Observable<any> =>
    this.http.delete('/availability/' + slotID);

  createAvailabilitySlot = (slot: WeeklyAvailabilitySlot): Observable<any> => {
    return this.http.post('/availability', slot);
  };

  deleteProfilePicture = (user: User): Observable<any> => {
    return this.http.delete('/profile/images');
  };

  getNotificationSettings = (profile_id): Observable<NotificationSettings[]> =>
    this.http.get(`/notification_settings/${profile_id}`);

  updateNotificationSettings = (
    notification_settings: NotificationSettings
  ): Observable<NotificationSettings[]> => {
    return this.http.put(
      `/notification_settings/${notification_settings.id}`,
      notification_settings
    );
  };

  checkCoupon = (coupon): Observable<any> =>
    this.http.post('/coupon/check', { coupon: coupon });

  getCoupons = (): Observable<any> => this.http.get('/coupon');

  getPlanPickerPlans = (): Observable<any> =>
    this.http.get('/plan_picker_plans');

  getSubscriptionStatus = (): Observable<SubscriptionStatus> =>
    this.http.get('/subscription_status');

  getSubscriptionBilling = (): Observable<SubscriptionBilling> =>
    this.http.get('/subscription_billing');

  getPlanByKind = (kind): Observable<any> => this.http.get('/plans/' + kind);

  getActiveSubscriptions = (): Observable<any> =>
    this.http.get('/subscription/active');

  cancelSubscription = (): Observable<any> => this.http.delete('/subscription');

  downgradeSubscription = (): Observable<any> =>
    this.http.post('/subscription/downgrade-to-free', {});

  getDowngradeOffer = (): Observable<{
    offer: {
      id: RetentionCouponId;
    } | null;
  }> => this.http.get('/subscription/get-downgrade-offer', {});

  downgradeToBasicUsingRetentionOffer = (
    coupon_id: RetentionCouponId
  ): Observable<any> =>
    this.http.post('/subscription/downgrade-to-basic-using-retention-offer', {
      coupon_id,
    });

  cancelScheduledSubscriptionChange = (): Observable<any> =>
    this.http.post('/subscription/cancel-scheduled-change', {});

  useLanguage(locale: string) {
    if (locale == 'de' || locale == 'en') {
      this.translate.setDefaultLang(locale);
      moment.locale(locale, {
        week: {
          dow: DAYS_OF_WEEK.MONDAY,
          doy: 0,
        },
      });

      this.cookies.put('iclocale', locale, {
        expires: moment().add(1, 'month').toDate(),
      });
    }
  }
  setLanguageOnStartup() {
    var locale = this.cookies.get('iclocale');
    if (locale) {
      this.useLanguage(locale);
    }
  }

  markLauncherComplete() {
    return this.http.put('/current_user', {
      finished_launcher_tasks: true,
    });
  }
}

export type RetentionCouponId =
  | 'offer_basic_6'
  | 'offer_basic_6_low'
  | 'offer_basic_3';
