import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Appointment } from '../../../entities/appointment.model';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: GenericHttpService) {}

  public getAppointment(id: any): Observable<any> {
    return this.http.get('/appointments/' + id);
  }

  public getAppointments(): Observable<any> {
    return this.http.get('/appointments');
  }

  public getAppointmentsForDay(date: Date): Observable<any> {
    return this.http.get(
      '/appointments/day/' + date.toISOString().split('T')[0]
    );
  }

  public saveAppointment(appointment: Appointment): Observable<Appointment> {
    return this.http.post('/appointments', appointment);
  }

  public acceptAppointment(id: number): Observable<Appointment> {
    return this.http.put('/appointments/' + id, {
      status: 'accepted',
    });
  }

  public cancelAppointment(id: number): Observable<Appointment> {
    return this.http.put('/appointments/' + id, {
      status: 'canceled',
    });
  }

  public declineAppointment(id: number): Observable<Appointment> {
    return this.http.put('/appointments/' + id, {
      status: 'declined',
    });
  }

  public markAppointmentAsCompleted(
    id: number,
    wasOnline: boolean,
    language: string,
    topic: string
  ): Observable<Appointment> {
    return this.http.put('/appointments/' + id, {
      status: 'completed',
      appointment_survey_attributes: {
        was_online: wasOnline,
        language,
        topic,
      },
    });
  }

  public updateAppointment(appointment: Partial<Appointment>): Observable<any> {
    return this.http.put('/appointments/' + appointment.id, appointment);
  }

  public deleteBlocker(appointment): Observable<any> {
    return this.http.delete('/appointments/' + appointment.id);
  }

  public getLatestAppointment(): Observable<Array<Appointment>> {
    return this.http.get('/latest_appointment');
  }

  public createInvoiceForAppointment(appointment): Observable<Appointment> {
    return this.http.post('/appointments/invoice', { id: appointment.id });
  }
  public getClientAppointments(): Observable<any[]> {
    return this.http.get('/client_appointments');
  }
  public getAppointmentsRequiringCompletion() {
    return this.http.get('/appointments/requiring_completion');
  }

  public getAppointmentByUid(uid: string): Observable<Appointment> {
    return this.http.get('/appointments/by_uid/' + uid);
  }
}
