<section class="dashboard-teaser"  *ngIf="appointmentsRequiringCompletion && appointmentsRequiringCompletion.length > 0 && currentUser">
  <header class="dashboard-teaser-header">
    <h3 class="dashboard-teaser-title">
      {{ "home.eap_completion.title" | translate }}
    </h3>
    <div
      class="qmarkCircle tooltip is-tooltip-multiline"
      [attr.data-tooltip]="'home.eap_completion.tooltip' | translate"
    ></div>
  </header>

  <div class="card">
    <div
      class="card-content appointment"
      *ngFor="let appointment of appointmentsRequiringCompletion"
    >
      <div class="columns is-vcentered">
        <div class="column appointment-details">
          <div class="client">
            <div class="profile-picture">
              <app-profile-picture
                [profile_id]="appointment.client.profile.id"
                [variant]="'thumbnail'"
              ></app-profile-picture>
            </div>
            <p class="appointment-title">
              <span *ngIf="currentUser.type == 'Therapist'">
                {{ appointment.client ? appointment.client.name : "Unknown" }}
              </span>
            </p>
          </div>
          <div class="appointment-time">
            <i class="icon icon-calendar"></i>
            <p>{{ appointment.start | momentFormat:'ddd, MMMM D [at] HH:mm' }}</p>
          </div>
        </div>
        <div class="column is-narrow">
      <a
            class="button is-warning"
            (click)="markEapCompletedConfirm(appointment)"
          >
            {{ 'booking.actions.mark_eap_session_as_completed' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
