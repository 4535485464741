// email-change-dialog.component.ts
import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-email-change-dialog',
  standalone: true,
  imports: [
    TranslateModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    NgIf,
  ],
  template: `
    <h2 mat-dialog-title>
      {{ 'settings.email-change-confirmation-title' | translate }}
    </h2>

    <mat-dialog-content>
      <p>{{ 'settings.email-change-confirmation-desc' | translate }}</p>
      <ul>
        <li>{{ 'settings.email-change-verification-needed' | translate }}</li>
        <li>{{ 'settings.email-change-logout-needed' | translate }}</li>
      </ul>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button (click)="onCancel()">
        {{ 'common.cancel' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="data.loading"
        (click)="onConfirm()"
      >
        <span *ngIf="!data.loading">{{
          'settings.confirm-email-change' | translate
        }}</span>
        <span *ngIf="data.loading">{{ 'common.loading' | translate }}</span>
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      ul {
        margin: 16px 0 16px 24px;
        padding: 0;
      }
      li {
        margin-bottom: 8px;
      }
    `,
  ],
})
export class EmailChangeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EmailChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { loading: boolean }
  ) {}

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
