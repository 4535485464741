import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';
import { NgxMaskDirective } from 'ngx-mask';
import { DatePipe, NgIf } from '@angular/common';
import { Moment } from 'moment-timezone';

@Component({
  selector: 'app-booking-form-blocker-summary',
  templateUrl: './booking-form-blocker-summary.component.html',
  styleUrls: ['./booking-form-blocker-summary.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgxMaskDirective,
    AutosizeModule,
    DatePipe,
    TranslateModule,
    NgxMaskDirective,
  ],
})
export class BookingFormBlockerSummaryComponent implements OnInit {
  @Input() requestBookingForm: FormGroup;
  @Input() onCancel: () => void;
  @Input() onSubmit: () => void;
  @Input() onChangeDate: () => void;
  @Input() timezoneText: string;

  blockerTimestamp: any;
  constructor() {}

  ngOnInit(): void {
    this.blockerTimestamp = (
      this.requestBookingForm.controls.appointmentDate.value as Moment
    )
      .toDate()
      .getTime();

    this.checkErrors();

    this.requestBookingForm.controls.duration.valueChanges.subscribe(() => {
      this.checkErrors();
    });
  }

  private checkErrors() {
    Object.keys(this.requestBookingForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.requestBookingForm.get(key).errors;
      if (controlErrors != null) {
        console.log('Control: ', key);
        console.log('Errors: ', controlErrors);
      }
    });
  }
  //   if the requestBookingForm is invalid, log why
}
