// launcher.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { createEditProfileLink } from '../../helpers/navigation_helper';
import { SettingsService } from '../../../components/settings/service/settings.service';
import { User } from 'src/app/entities/user.model';
import { ProfileService } from '../../../components/profile/service/profile.service';
import { LauncherShareDialogComponent } from './launcher-share-dialog/launcher-share-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

interface LauncherStep {
  id: string; // This will match our translation key structure
  hasCalendarCheck?: boolean;
  action: () => void;
}

@Component({
  selector: 'app-launcher',
  templateUrl: './launcher.component.html',
  styleUrls: ['./launcher.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class LauncherComponent implements OnInit {
  @Input() currentUser: User;
  steps: LauncherStep[] = [];
  expandedStep: number | null = null;
  currentStepIndex: number = 0;
  private readonly PROGRESS_KEY = 'launcher-progress';

  constructor(
    private snackBar: MatSnackBar,
    private settingsService: SettingsService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initializeSteps();
    this.initializeProgress();
  }

  private initializeProgress(): void {
    // If calendar is set up, we start at least at step 1
    if (
      this.currentUser?.profile?.calendar_enabled ||
      this.currentUser?.profile?.ignoreCalendar
    ) {
      // Read saved progress, but ensure we're at least at step 1
      const savedProgress = Number(
        localStorage.getItem(this.PROGRESS_KEY) || '1'
      );
      this.currentStepIndex = Math.min(
        Math.max(1, savedProgress),
        this.steps.length - 1
      );
    } else {
      // Calendar not set up, start at beginning
      this.currentStepIndex = 0;
    }

    // Set expanded step to current step
    this.expandedStep = this.currentStepIndex;
  }

  private initializeSteps(): void {
    this.steps = [
      {
        id: 'calendar',
        hasCalendarCheck: true,
        action: () =>
          (window.location.href = '/dashboard/home/settings/calendar'),
      },
      {
        id: 'social',
        action: () => this.copyProfileLink(),
      },
      {
        id: 'linkedin',
        action: () => this.openSharingModal(),
      },
      {
        id: 'community',
        action: () =>
          window.open(
            'https://community.complicated.life/c/introduce-yourself/',
            '_blank'
          ),
      },
      {
        id: 'events',
        action: () =>
          window.open(
            'https://community.complicated.life/c/upcoming-events-itcomplicated/',
            '_blank'
          ),
      },
      {
        id: 'bookshelf',
        action: () => this.openBookshelf(),
      },
    ];
  }

  isStepComplete(index: number): boolean {
    if (index === 0) {
      return (
        this.currentUser?.profile?.calendar_enabled ||
        this.currentUser?.profile?.ignoreCalendar ||
        this.currentStepIndex > 0
      );
    }
    return this.currentStepIndex > index;
  }

  isStepExpanded(index: number): boolean {
    return index === this.expandedStep;
  }

  isStepDisabled(index: number): boolean {
    return index > this.currentStepIndex;
  }

  onPanelOpened(index: number): void {
    if (!this.isStepDisabled(index)) {
      this.expandedStep = index;
    }
  }

  onPanelClosed(index: number): void {
    if (this.expandedStep === index) {
      this.expandedStep = null;
    }
  }

  markStepDone(index: number): void {
    if (index + 1 > this.currentStepIndex) {
      this.currentStepIndex = index + 1;
      localStorage.setItem(this.PROGRESS_KEY, this.currentStepIndex.toString());

      if (this.currentStepIndex === this.steps.length) {
        this.settingsService.markLauncherComplete().subscribe(
          (updatedUserResponse) => {
            this.sharedService.setCurrentUser(updatedUserResponse.user);
          },
          (error) => {
            console.error('Error marking launcher as completed', error);
          }
        );
        this.expandedStep = null;
      } else {
        // Expand the next incomplete step
        this.expandedStep = this.currentStepIndex;
      }
    }
  }

  setIgnoreCalendar(): void {
    this.currentUser.profile.ignoreCalendar = true;
    this.profileService.updateItem(
      this.currentUser.profile,
      this.currentUser.profile.id
    );
    this.markStepDone(0); // This will now automatically expand the next step
  }

  copyProfileLink(): void {
    navigator.clipboard.writeText(this.currentUser.profile.link);
    this.translate
      .get('launcher.share.copied_notification')
      .subscribe((msg: string) => {
        this.snackBar.open(
          msg,
          this.translate.instant('launcher.share.close'),
          {
            duration: 3000,
          }
        );
      });
  }

  // Update the openSharingModal method
  openSharingModal(): void {
    const dialogRef = this.dialog.open(LauncherShareDialogComponent, {
      width: '600px',
      panelClass: 'share-dialog-container',
      data: { profileLink: this.currentUser.profile.link },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Optional: handle any post-dialog actions
    });
  }

  openBookshelf(): void {
    window.open(
      createEditProfileLink(
        this.currentUser.locale,
        this.currentUser.profile,
        'bookshelf'
      ),
      '_blank'
    );
  }
}
